
import React, { Component } from 'react';

import { connect } from 'react-redux';
import profileimg from '../../assets/images/profile-user (1).png';
import { getMyProfile } from '../../store/actions/authActions';
import { uploadImage } from '../../store/actions/bookAction';
import LottieAnimation from '../../components/Lottie/lottieAnimation';




class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {

            errors: {},
            serverError: {},
            isLoading: false,
            User_ID: this.props.user?.User_ID,
            Full_Name: '',
            Email: '',
            About: '',
            Date_Of_Birth: '',
            Phone_Num: '',
            profile: {},
            isEditabled: false,
            image:'',

        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    componentDidMount() {

        this.setState({isLoading:true})
        console.log(this.props.user)
        this.props.getMyProfile().then((res) => {
            if (res.status) {
                this.setState({
                    profile:res.content,
                   isLoading:false,
                },() => {
                    console.log(this.state.profile)
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }

    onEdit = () => {
        this.setState({
            isEditabled: true,
        })
    }

    onImageChange(event) {

        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                image: reader.result
            }, () => {
                this.uploadImage();
            });

        }

        reader.readAsDataURL(file)

    }
    uploadImage() {
        const payload = new FormData();
        payload.append('imageType', 'PROFILE');
        payload.append('photo', this.state.file);
        this.setState({ isUploading: true })
        console.log(payload)
        this.props.uploadImage(payload).then((res) => {
            console.log(res.content)
            this.setState({ isUploading: false })
            if (res.content.length > 0) {
                console.log(res.content[0].url)
               
                    this.setState({ image: res.content[0].url })
            }
        }).catch((err) => {
            this.setState({ isUploading: false })
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    this.setState({ errors: { image: obj["msg"] } });
                });
            }

        })

    }

    render() {

        const { isLoading, isEditabled,profile } = this.state;
        const { user } = this.props

        if (isLoading) {
            return (
                //<div className="loader-large"></div>
                <LottieAnimation/>
            )
        }

        return (
            <div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-5 managebookContainer">



                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-3">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3 ">
                                                <label className="profileinputLabel">User name</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12  mt-3 ">
                                                <input className="profileinput" placeholder="Subject" name='Full_Name' onChange={this.onChange} value={profile?.Full_Name} disabled={!isEditabled}></input>

                                            </div>

                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3 ">
                                                <label className="profileinputLabel" >Email</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12   mt-3 ">
                                                <input className="profileinput" placeholder="Subject" name='Email' onChange={this.onChange} value={profile?.Email} disabled={!isEditabled}></input>

                                            </div>

                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3  ">
                                                <label className="profileinputLabel">Phone Number</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-3  ">
                                                <input className="profileinput" placeholder="Subject" name='Phone_Num' onChange={this.onChange} value={profile?.Phone_Num} disabled={!isEditabled}></input>

                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3  ">
                                                <label className="profileinputLabel">Date of Birth</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-3  ">
                                                <input className="profileinput" placeholder="Subject" name='Date_Of_Birth' onChange={this.onChange} value={profile?.Date_Of_Birth} disabled={!isEditabled}></input>

                                            </div>

                                            {/* <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3  ">
                                                <label className="profileinputLabel">Account Status</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-3  ">
                                                <select className="profileinput" name='Status' onChange={this.onChange} value={profile?.Status} disabled={!isEditabled}>
                                                    <option></option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>Deactive</option>

                                                </select>

                                            </div> */}



                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                                                <p className="poppins_semibold managebookheading">Change Password</p>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3  ">
                                                <label className="profileinputLabel">Old Password</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-3  ">
                                                <input className="profileinput" placeholder="Enter Password" type="password" onChange={this.onChange} disabled={!isEditabled}></input>


                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3  ">
                                                <label className="profileinputLabel">New Password</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-3  ">
                                                <input className="profileinput" placeholder="Enter Password" type="password" onChange={this.onChange} disabled={!isEditabled}></input>


                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 text_align_R mt-3  ">
                                                <label className="profileinputLabel">Retype Password</label>

                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12 mt-3  ">
                                                <input className="profileinput" placeholder="Enter Password" type="password" onChange={this.onChange} disabled={!isEditabled}></input>


                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mt-4 ">
                                                <button className="profileSaveButton" disabled={!isEditabled}>Save</button>
                                            </div>


                                        </div>


                                    </div>


                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-3">
                                        <div className="row">

                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 mt-3 text_align_R">
                                                <label className="profileinputLabel">About</label>

                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12  mt-3 ">
                                                <textarea className="profile_TextArea" name='About' onChange={this.onChange} value={user?.About} disabled={!isEditabled}></textarea>

                                            </div>

                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 mt-3 text_align_R">
                                                <label className="profileinputLabel">Profile</label>

                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12  mt-3 ">
                                                <div className="profileContainer text-center">

                                                    <img className="profileimg" src={this.state.image === "" ? profileimg : this.state.image} />

                                                    
                                                </div>
                                                <button className="profileEditButton mt-4" onClick={(e) => this.upload.click()} >Upload Image<input type="file" onChange={(e) => this.onImageChange(e)} name="image" ref={(ref) => this.upload = ref} style={{ display: 'none' }} /></button>

                                            </div>




                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">



                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-3 col-sm-12 col-12 mt-3 text_align_R">
                                            </div>
                                            <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12  mt-3 ">

                                                <div className="row">

                                                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6 text-center mt-3 ">
                                                        <button className="profileEditButton" onClick={this.onEdit} disabled={isEditabled}>Edit</button>
                                                    </div>

                                                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-6 text-center mt-3 ">
                                                        <button className="profileSaveButton" disabled={!isEditabled}>Save</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>





                        </div>


                    </div>


                </div>




            </div>

        )
    }

}

MyProfile.propTypes = {

};


const mapStateToProps = state => ({
    auth: state.auth,
    user: state.auth.user
});

const mapDispatchToProps = ({
    getMyProfile,
    uploadImage

})
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
