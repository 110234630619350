import React, { Component } from "react";
import PropTypes from "prop-types";
import Epub from "epubjs/lib/index";
import defaultStyles from "./style";
import pgaudio from '../../../../assets/audio/page-flip-6.mp3'


global.ePub = Epub; // Fix for v3 branch of epub.js -> needs ePub to by a global var

class EpubView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toc: [],

    };
    this.viewerRef = React.createRef();
    this.location = props.location;

    this.book = this.rendition = this.prevPage = this.nextPage = null;
    //this.div = React.createRef();
  }



  componentDidMount() {
    this.initBook(true);
    document.addEventListener("keyup", this.handleKeyPress, false);
  }

  initBooks(url) {
    const {  tocChanged, epubInitOptions,book } = this.props;
    //  console.log(this.props)
    if (this.book) {
      this.book.destroy();
      console.log("destroy")
    }
    console.log(url)
    this.book = new Epub(url, epubInitOptions);
    this.book.loaded.navigation.then(({ toc }) => {
      console.log(toc)
      this.setState(
        {
          isLoaded: true,
          toc: toc
        },
        () => {
          tocChanged && tocChanged(toc);

          this.initReader();
        }
      );
    });
  }

  initBook() {
    const { url, tocChanged, epubInitOptions,book } = this.props;
    //  console.log(this.props)
    if (this.book) {
      this.book.destroy();
      console.log("destroy")
    }
    console.log(url)
    this.book = new Epub(url, epubInitOptions);
    this.book.loaded.navigation.then(({ toc }) => {
      console.log(toc)
      this.setState(
        {
          isLoaded: true,
          toc: toc
        },
        () => {
          tocChanged && tocChanged(toc);

          this.initReader();
        }
      );
    });
  }

  componentWillUnmount() {
    this.book = this.rendition = this.prevPage = this.nextPage = null;
    document.removeEventListener("keyup", this.handleKeyPress, false);
  }

  shouldComponentUpdate(nextProps) {


    return (
      !this.state.isLoaded ||
      nextProps.location !== this.props.location ||
      nextProps.location !== this.props.location
    );
  }
  componentWillReceiveProps(nextProps) {

    if (this.props.isPageScroll !== nextProps.isPageScroll) {
      this.initBook()
    }
    if (this.props.highlighted !== nextProps.highlighted) {
      this.initBook()
    }
    if (this.props.isModeLight !== nextProps.isModeLight) {
      this.initBook()
    }
    if (this.props.book !== nextProps.book) {
      console.log('here2')
      this.initBook()
    }
    if (this.props.url !== nextProps.url) {
      console.log(nextProps.url)
      this.initBooks(nextProps.url)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location !== this.props.location &&
      this.location !== this.props.location
    ) {
      console.log('location')
      this.rendition.display(this.props.location);
    }
    if (prevProps.url !== this.props.url) {
      console.log('url')
      this.initBook();
    }
    // if (prevProps.book !== this.props.book) {
    //   console.log('book')
    //   this.initBook();
    // }
    console.log(this.props.isPageScroll, prevProps.isPageScroll)

  }


  

  initReader() {
    const { toc } = this.state;
    const { location, epubOptions, getRendition } = this.props;
    console.log(this.props)
    const node = this.viewerRef.current;
    //console.log(this.props.isPageScroll)
    if(this.props.isModeLight){
    if (this.props.isPageScroll) {
      this.rendition = this.book.renderTo(node, {
        // contained: true,
        width: "100%",
        height: "100%",
        flow: "scrolled-doc",
        ...epubOptions
      });
      console.log(this.rendition)
      this.rendition.themes.default({
        'body': {
            '-webkit-touch-callout': 'none', /* iOS Safari */
            '-webkit-user-select': 'none', /* Safari */
            '-khtml-user-select': 'none', /* Konqueror HTML */
            '-moz-user-select': 'none', /* Firefox */
            '-ms-user-select': 'none', /* Internet Explorer/Edge */
            'user-select': 'none',
            'overflowX': 'hidden',

        },
       
    });
    } else {
      if (this.props.direction === "rtl") {
        this.rendition = this.book.renderTo(node, {
          contained: true,
          width: "100%",
          height: "100%",
          defaultDirection: this.props.direction,
          direction: this.props.direction,
          //flow: "scrolled-doc",
          ...epubOptions
        });
        this.rendition.themes.default({
          'body': {
              '-webkit-touch-callout': 'none', /* iOS Safari */
              '-webkit-user-select': 'none', /* Safari */
              '-khtml-user-select': 'none', /* Konqueror HTML */
              '-moz-user-select': 'none', /* Firefox */
              '-ms-user-select': 'none', /* Internet Explorer/Edge */
              'user-select': 'none',
              'overflowX': 'hidden',
             

          },
          'p.Urdu-Justified-text':{
            'font-family': 'MehrNastaliq !important',
            'font-weight': '500 !important',
           
  
          }
      });
      } else {
        this.rendition = this.book.renderTo(node, {
          contained: true,
          width: "100%",
          height: "100%",
          //flow: "scrolled-doc",
          ...epubOptions
        });
        this.rendition.themes.default({
          'body': {
              '-webkit-touch-callout': 'none', /* iOS Safari */
              '-webkit-user-select': 'none', /* Safari */
              '-khtml-user-select': 'none', /* Konqueror HTML */
              '-moz-user-select': 'none', /* Firefox */
              '-ms-user-select': 'none', /* Internet Explorer/Edge */
              'user-select': 'none',
              'overflowX': 'hidden',

          },

          'p.Urdu-Justified-text':{
            'font-family': 'MehrNastaliq !important',
            'font-weight': '500 !important',
  
          }
         
      });
    //   this.rendition.themes.default({
    //     'body': {
    //         'background':'#000'
    //     },
    //     'p':{
    //       'color':'#fff !important'
    //     }

    // });
      }
    }
  }else{
    if (this.props.isPageScroll) {
      this.rendition = this.book.renderTo(node, {
        // contained: true,
        width: "100%",
        height: "100%",
        flow: "scrolled-doc",
        ...epubOptions
      });
      this.rendition.themes.default({
        'body': {
          '-webkit-touch-callout': 'none', /* iOS Safari */
          '-webkit-user-select': 'none', /* Safari */
          '-khtml-user-select': 'none', /* Konqueror HTML */
          '-moz-user-select': 'none', /* Firefox */
          '-ms-user-select': 'none', /* Internet Explorer/Edge */
          'user-select': 'none',
          'background':'#000',
          'overflowX': 'hidden',
        },
        'p':{
          'color':'#fff !important',
          'font-family': 'MehrNastaliq !important',
          'font-weight': '500 !important'

        },
        'h1':{
          'color':'#fff !important',
          'font-family': 'MehrNastaliq !important',
          'font-weight': '500 !important'

        }
    });
    } else {
      if (this.props.direction === "rtl") {
        this.rendition = this.book.renderTo(node, {
          contained: true,
          width: "100%",
          height: "100%",
          defaultDirection: this.props.direction,
          direction: this.props.direction,
          //flow: "scrolled-doc",
          ...epubOptions
        });
        this.rendition.themes.default({
          'body': {
            '-webkit-touch-callout': 'none', /* iOS Safari */
            '-webkit-user-select': 'none', /* Safari */
            '-khtml-user-select': 'none', /* Konqueror HTML */
            '-moz-user-select': 'none', /* Firefox */
            '-ms-user-select': 'none', /* Internet Explorer/Edge */
            'user-select': 'none',
            'background':'#000',
            'overflowX': 'hidden',

          },
          'p':{
            'color':'#fff !important',
            'font-family': 'MehrNastaliq !important',
            'font-weight': '500 !important'
  
          },
          'h1':{
            'color':'#fff !important',
            'font-family': 'MehrNastaliq !important',
            'font-weight': '500 !important'
  
          }
      });
      } else {
        this.rendition = this.book.renderTo(node, {
          contained: true,
          width: "100%",
          height: "100%",
          //flow: "scrolled-doc",
          ...epubOptions
        });
        this.rendition.themes.default({
          'body': {
              '-webkit-touch-callout': 'none', /* iOS Safari */
              '-webkit-user-select': 'none', /* Safari */
              '-khtml-user-select': 'none', /* Konqueror HTML */
              '-moz-user-select': 'none', /* Firefox */
              '-ms-user-select': 'none', /* Internet Explorer/Edge */
              'user-select': 'none',
              'background':'#000',
              'overflowX': 'hidden',

            },
            'p':{
              'color':'#fff !important',
              'font-family': 'MehrNastaliq !important',
              'font-weight': '500 !important'
    
            },
            'h1':{
              'color':'#fff !important',
              'font-family': 'MehrNastaliq !important',
              'font-weight': '500 !important'
    
            }
      });
     
      }
    }
  }
    console.log(this.rendition)
    this.prevPage = () => {
      let audio = new Audio(pgaudio)
      audio.play()
      if (this.props.direction === "rtl") {
      } else {
        this.rendition  && this.rendition.prev();
      }
    };
    this.nextPage = () => {
      let audio = new Audio(pgaudio)
      audio.play()
      if (this.props.direction === "rtl") {
        this.rendition  &&  this.rendition.prev();
      } else {
        this.rendition  &&    this.rendition.next();
      }
    };
    this.registerEvents();
    this.props.getRendition && this.props.getRendition(this.rendition);
    if (typeof location === "string" || typeof location === "number") {
      this.rendition.display(location);

    } else if (toc.length > 0 && toc[0].href) {
      this.rendition.display(toc[0].href);
    } else {
      this.rendition.display();
    }
  }

  registerEvents() {
    const { handleKeyPress, handleTextSelected } = this.props;
    this.rendition.on("locationChanged", this.onLocationChange);
    this.rendition.on("keyup", handleKeyPress || this.handleKeyPress);
    if (handleTextSelected) {
      this.rendition.on('selected', handleTextSelected);
    }
  }

  onLocationChange = loc => {
    const { location, locationChanged } = this.props;

    const newLocation = loc && loc.start;
    if (location !== newLocation) {
      this.location = newLocation;
      locationChanged && locationChanged(newLocation);
    }
    
  };
  myFunction = () => {
    console.log(window.getSelection().toString())
  }

  renderBook() {
    const { styles } = this.props;
    return (
      <>
            {this.props.direction === 'rtl' && (

    <div className="epubb-viewUrdu" ref={this.viewerRef}  onMouseUpCapture={() => this.myFunction()} />
            )}
             {this.props.direction === 'ltr' && (

<div className="epubb-view" ref={this.viewerRef}  onMouseUpCapture={() => this.myFunction()} />
        )}
    </>
    )
  }

  handleKeyPress = ({ key }) => {
    key && key === "ArrowRight" && this.nextPage();
    key && key === "ArrowLeft" && this.prevPage();
  };

  render() {
    const { isLoaded } = this.state;
    const { loadingView, styles } = this.props;
    return (
      <div style={styles.viewHolder}>
        {(isLoaded && this.renderBook()) || loadingView}
      </div>
    );
  }
}

EpubView.defaultProps = {
  loadingView: null,
  locationChanged: null,
  tocChanged: null,
  styles: defaultStyles,
  epubOptions: {},
  epubInitOptions: {}
};

EpubView.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  loadingView: PropTypes.element,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  styles: PropTypes.object,
  epubInitOptions: PropTypes.object,
  epubOptions: PropTypes.object,
  getRendition: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleTextSelected: PropTypes.func
};

export default EpubView;