
import React, { Component } from 'react';
import backgoround_img from '../../assets/images/Login/pexels-photo-3747505.svg'
import backgoround_img_shadow from '../../assets/images/Login/loginbackground.svg'
import logo from '../../assets/images/Login/logo.svg'
import { Link, withRouter } from 'react-router-dom';
import { loginUser, updatePublisher } from '../../store/actions/authActions';




import { connect } from 'react-redux';

import Noty from 'noty';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            password2: '',
            otp:'',
            Full_Name:'',
            Email:'',
            profile:{},
            errors: {},
            serverError: {},
            isSigningIn: false,
            isLoading: false,
        };
      
    }
    componentDidMount() {

            if (this.props.auth.isAuthenticated) {
                this.props.history.push('/');
            }

        }

        onCreateAccount = () => {
            this.props.history.push('/register');
        }
        onUpdate = (e) => {
            e.preventDefault()
            this.setState({isLoading:true})
            this.props.updatePublisher({
                "Email": this.state.email,
                "Password": this.state.password,
                "Password2": this.state.password2,
                "Otp": this.state.otp,
            }).then((res) => {
                this.setState({ isLoading: false })
                console.log(res)
                if (res.status) {
                    this.props.history.push('/login')
                    new Noty({
                        text: "Succsessfully Updated",
    
                        type: "success",
    
                        timeout: 2000
                    }).show()
                        .then(() => { }
                        )
                } else {
                    new Noty({
                        text: "Something went wrong",
    
                        type: "error",
                        timeout: 2000
                    }).show();
                }
            }).catch((err) => {
                this.setState({ isLoading: false })
                console.log(err)
                var validationError = {}
                var serverError = []
                if (err.hasOwnProperty('validation')) {
                    err.validation.map(obj => {
                        if (obj.hasOwnProperty('param')) {
                            validationError[obj["param"]] = obj["msg"]
                        } else {
                            serverError = [...serverError, obj]
                        }
                    });
                    this.setState({ errors: validationError });
                    this.setState({ serverError: serverError });
                } else {
                    this.setState({ serverError: [{ "msg": "server not responding" }] })
                }
            })
        }

        onLogin = () => {

            const userData = {
                email: this.state.email,
                password: this.state.password
            };
            this.setState({ isSigningIn: true })

            this.props.loginUser(userData).then(res => {
                this.setState({ isSigningIn: false })

                if (res.status) {
                    this.props.history.push('/');
                    return
                } else {
                    return
                }

            }).catch(err => {
                this.setState({ isSigningIn: false })
                var validationError = {}
                var serverError = []
                console.log(err.hasOwnProperty('validation'))

                if (err.hasOwnProperty('validation')) {
                    err.validation.map(obj => {
                        if (obj.hasOwnProperty('param')) {
                            validationError[obj["param"]] = obj["msg"]
                        } else {
                            serverError = [...serverError, obj]
                        }
                        console.log(obj["msg"])
                    });
                    this.setState({ errors: validationError });
                    this.setState({ serverError: serverError });
                } else {
                    this.setState({ serverError: [{ "msg": "server not responding" }] })
                }
            });


    }
    componentWillMount() {
        if (this.props != null && this.props.location.state != null && this.props.location.state.email) {
    
    
            //console.log(this.props.location.state.book)
            this.setState({
                email: this.props.location.state.email
    
            })
        }
    
    }
    componentWillReceiveProps(nextProps) {

    }

    renderServerError() {
        if (this.state.serverError != null && this.state.serverError.length > 0) {
            return (

                <div className="form-group alert alert-danger" role="alert" >
                    <strong className="pr-2">Oh snap!  {"  "}</strong>
                    {this.state.serverError[0].msg}

                </div>
            )
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { errors, isLoading } = this.state
        return (
            <div className="div-cotainer">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
                    <div className="row  ">
                        <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12 col-12 p-0  ">


                            <img className="Background_img" src={backgoround_img}/>
                            <img className="Background_img" src={backgoround_img_shadow}/>
                            {/* <p className="img_Text poppins_regular">2020 The Little Book Company  All rights reserved.</p> */}
                        </div>
                    
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 p-0  text-center ">
                            <div className="login-container">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-2 ">

                                    <img src={logo}/>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4 ">
                                    <p className="welcome-text poppins_bold mb-0">Welcome to Little Book Company</p>
                                    <p className="login_text poppins_regular mt-2  mb-0">Hope you are doing well</p>
                                

                                </div>
                                {this.renderServerError()}

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 text-left ">
                                    <p className="login_text poppins_medium mt-2  mb-0">Otp</p>
                                    <input className="logininout" name="otp" onChange={this.onChange}></input>
                                    {errors.otp && <div className="invaliderrorLogin">{errors.otp}</div>}

                          

                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 text-left ">
                                    <p className="login_text poppins_medium mt-2  mb-0">New Password</p>
                                    <input className="logininout" type="password" name="password" onChange={this.onChange}></input>
                                    {errors.password && <div className="invaliderrorLogin">{errors.password}</div>}

                          

                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 text-left ">
                                    <p className="login_text poppins_medium mt-2  mb-0">Re-Enter New Password</p>
                                    <input className="logininout" type="password" name="password2" onChange={this.onChange}></input>
                                    {errors.password2 && <div className="invaliderrorLogin">{errors.password2}</div>}

                          

                                </div>

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 text-right ">
                          
                                 

                                </div>
                                {isLoading && <div className="loader-medium"></div>}
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4 ">
                                  <button className="login_btn poppins_bold" onClick={this.onUpdate}>Save</button>

                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4 ">

                                <label className="login_text poppins_medium mt-2  mb-0">Don't have an account? 
                                <Link to="/register">

                                <label className="poppins_bold pointerr"> Create Account</label> 
                                </Link>
                                </label>
                                
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>

        )
    }

}

ChangePassword.propTypes = {

};


const mapStateToProps = state => ({
    auth: state.auth,

});

const mapDispatchToProps = ({
    loginUser,
    updatePublisher
})
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
