
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { getConversionBooks } from '../../store/actions/bookAction';
import earingCardIcon from '../../assets/images/Dashborad/earingCardIcon.svg'
import cost from '../../assets/images/Dashborad/cost.svg'
import buy from '../../assets/images/Dashborad/buy.svg'
import openbook from '../../assets/images/Dashborad/openbook.svg'
import editbook from '../../assets/images/Dashborad/edit.svg'
import plus from '../../assets/images/Dashborad/plus.svg'
import bookCard from '../../assets/images/Dashborad/bookCard.svg'
import book1 from '../../assets/images/Dashborad/book1.svg'
import book2 from '../../assets/images/Dashborad/book2.svg'
import book3 from '../../assets/images/Dashborad/book3.svg'
import profile from '../../assets/images/Dashborad/profile.svg'
import deleteImg from '../../assets/images/Dashborad/delete.svg'
import star from '../../assets/images/Dashborad/star.svg'
import NoFile from '../../assets/images/NoFile.png'

import { Link, withRouter } from 'react-router-dom';
import { getTotalEarning } from '../../store/actions/orderAction';
import { getTotalOrdersAndBook, getTopSellingBooks, getLatestBook, getPieChartValue } from '../../store/actions/dashboardAction';

import { getAllOrder } from '../../store/actions/orderAction';
import Shimmer from "react-shimmer-effect";
import { ShimmerThumbnail } from "react-shimmer-effects";

import SvgLoader from '../../components/Svg/svgLoader';

import { PieChart } from 'react-minimal-pie-chart';

import { Chart } from "react-google-charts";




var cx = require('classnames');



const data = [
    ["Element", "Density", { role: "style" }],
    ["Copper", 15, "#ACACAC"], // RGB value
    ["Silver", 6, "#ACACAC"], // English color name
    ["Gold", 15, "#FAC85B"],
    ["Silver", 7, "#ACACAC"], // English color name
    ["Silver", 6, "#ACACAC"], // English color name
    ["Silver", 5, "#ACACAC"], // English color name


    // ["Platinum", 21.45, "color: #e5e4e2"] // CSS-style declaration
];

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalearning: "",
            totalBooks: "",
            totalorders: "",
            bookList: [],
            newBookList: [],
            englishBooks: 0,
            urduBooks: 0,
            earning: 0,
            bookSoldTotal: 0,
            isLoadingEarning: false,
            isLoadingTopSelling: false,
            // isLoadingTotalSold:false,
            isLoadingLatest: false,
            isLoadingTotalBooks: true,
            //isLoadingPie:false,

        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    getTotalEarning = (list) => {
        // if(list.length > 0){
        //     var earning = 0
        //     var bookSoldTotal = 0
        //     for(var i = 0 ; i < list.length; i++){
        //         earning = earning + parseInt(list[i].order_book?.Amount)
        //         bookSoldTotal++
        //     }


        //     console.log(earning) 
        //     this.setState({earning: earning, bookSoldTotal: bookSoldTotal})           
        // }
        console.log('List : ', list)

        if (list.length > 0) {
            var earning = 0
            var bookSoldTotal = 0

            for (var i = 0; i < list.length; i++) {

                //NEW calculation done by raja
                if(list[i].order_book?.currency == 'PKR'){
                    earning = earning + parseInt(list[i].bookAmount)
                    bookSoldTotal++
                }else{
                    earning = earning + parseInt(list[i].bookAmount * list[i].order_book.dollarRate)
                    bookSoldTotal++
                }

                // OLD calculation not by me
                // if (list[i]?.order_book?.Payment_Method === "PayPal") {
                //     earning = earning + parseInt(list[i].book?.Price_USD * 150)
                //     bookSoldTotal++
                // }
                // else {

                //     earning = earning + parseInt(list[i].book?.Price)
                //     bookSoldTotal++
                // }
            }
        }
        console.log(earning)
        this.setState({ earning: earning, bookSoldTotal: bookSoldTotal })


    }

    componentDidMount() {

        this.setState({
            isLoadingEarning: true,
            isLoadingTopSelling: true,
            //  isLoadingTotalSold:true,
            isLoadingTotalBooks: true,
            isLoadingLatest: true,
        })


        this.props.getAllOrder().then((res) => {
            console.log(res.content)
            if (res.status) {
                this.setState({
                    orderList: res.content,
                    isLoadingEarning: false,
                }, () => {
                    this.getTotalEarning(res.content)
                    // this.setState({ ["SORT" + 'createdAt']: 'DESC' })
                    // let { orderList, OrderListFiltered } = this.state
                    // let orderListSorted = orderList.sort(this.sortArrByOrder('createdAt', 'DESC'))
                    // let OrderListFilteredSorted = OrderListFiltered.sort(this.sortArrByOrder('createdAt', 'DESC'))
                    // this.setState({ orderList: orderListSorted, OrderListFiltered: OrderListFilteredSorted })
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getPieChartValue(30).then((res) => {
            console.log(res.content?.UrduBooks)
            if (res.status) {
                this.setState({
                    englishBooks: parseInt(res.content?.EnglishBooks, 10),
                    urduBooks: parseInt(res.content?.UrduBooks, 10),
                    totalBooks: parseInt(res.content?.EnglishBooks, 10) + parseInt(res.content?.UrduBooks, 10),
                    isLoadingTotalBooks: false,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getTotalEarning(600).then((res) => {

            console.log('Get total earning : ', res.content)
            if (res.content[0]?.order_book?.total_amount) {
                this.setState({
                    totalearning: res.content[0]?.order_book?.total_amount,
                })

            }
            else {
                this.setState({
                    totalearning: "",
                })
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getTotalOrdersAndBook().then((res) => {
            console.log(res.content)
            if (res.status) {
                this.setState({
                    totalorders: res.content[0]?.total_order,
                    //totalBooks: res.content[0]?.book?.total_book,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getTopSellingBooks().then((res) => {
            console.log(res.content)
            if (res.status) {
                this.setState({
                    bookList: res.content,
                    isLoadingTopSelling: false,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getLatestBook().then((res) => {
            console.log(res.content)
            if (res.status) {
                this.setState({
                    newBookList: res.content,
                    isLoadingLatest: false,

                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }


    viewMore = () => {
        this.props.history.push('/managebook', { book: this.state.newBookList });
    }




    render() {

        const { isLoadingEarning, isLoadingTopSelling, isLoadingLatest, isLoadingTotalBooks, englishBooks, urduBooks, totalearning } = this.state;

        return (
            <div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 AllbookContainer pt-3  pb-3">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                <p className="poppins_semibold managebookheading">Welcome Author's Dashboard</p>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3 ">

                                                {isLoadingEarning ? <ShimmerThumbnail height={103} className="earing_card"></ShimmerThumbnail>
                                                    :
                                                    <div className="earing_card">
                                                        <img src={earingCardIcon}></img>
                                                        <img className="costinner" src={cost}></img>
                                                        <label className="ml-2 mb-0 earningText poppins_semibold">Author Earning</label> <br></br>
                                                        <div className="text-right mt-2">
                                                            <label className=" mr-3  mb-0 earningAmount poppins_bold">
                                                                {/* Auther earning is 70% of total earning */}
                                                                {this.state.earning == 0 ? '0.00 RS' : Math.round(this.state.earning * 0.7) + '.00 RS'}</label>
                                                        </div>


                                                    </div>

                                                }
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3 ">
                                                
                                                {isLoadingEarning ? <ShimmerThumbnail height={103} className="totalSold_card"></ShimmerThumbnail>
                                                    :
                                                    <div className="totalSold_card">
                                                        <img src={earingCardIcon}></img>
                                                        <img className="costinner" src={buy}></img>
                                                        <label className="ml-2 mb-0 earningText poppins_semibold">Total Book Sold</label> <br></br>
                                                        <div className="text-right mt-2">
                                                            <label className=" mr-3  mb-0 earningAmount poppins_bold">
                                                                {this.state.bookSoldTotal == 0 ? '0' : this.state.bookSoldTotal}</label>
                                                        </div>


                                                    </div>

                                                }

                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-3 ">
                                                {isLoadingTotalBooks ? <ShimmerThumbnail height={103} className="totalBook_card"></ShimmerThumbnail>
                                                    : <div className="totalBook_card ">
                                                        <img src={earingCardIcon}></img>
                                                        <img className="costinner" src={openbook}></img>
                                                        <label className="ml-2 mb-0 earningText poppins_semibold">Total Books</label> <br></br>
                                                        <div className="text-right mt-2">
                                                            <label className=" mr-3  mb-0 earningAmount poppins_bold">
                                                                {this.state.totalBooks == '' ? '0' : this.state.totalBooks}</label>
                                                        </div>
                                                        <img className="editicon" src={editbook}></img>


                                                    </div>
                                                }


                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 mt-3 ">
                                                <div className="sellinghistory_card ">
                                                    <div className="row pl-3 pr-3 ">

                                                        <div className="col-8 p-0 ">

                                                            <label className="ml-2 mb-0 selling_history_text poppins_medium">Selling History</label> <br></br>
                                                        </div>
                                                        <div className="col-4 text-right p-0  ">
                                                            <Link to="/sellinghistory">


                                                                <label className=" mr-3 mb-0 earningText pointerr poppins_medium">See All</label> <br></br>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <Chart
                                                        chartType="ColumnChart"
                                                        width="100%"
                                                        height="215px"
                                                        data={data}
                                                    />
                                                    <div className="text-center mt-3">
                                                        <button className="btn-sellinghistory">View Details</button>
                                                    </div>


                                                </div>


                                                <div className="addnewbookcard  text-center mt-3">
                                                    <Link to="/addnewbook">

                                                        <img className="plusicon" src={plus}></img><br></br>
                                                    </Link>
                                                    <label className="poppins_regular addnewbookText">Add New Book</label>
                                                </div>



                                            </div>
                                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mt-3 ">
                                                <div className="bookcategorycard ">
                                                    <div className="row pl-3 pr-3 ">
                                                        <div className="col-8 p-0 ">
                                                            <label className="ml-2 mb-0 selling_history_text poppins_medium">Books Category Stats</label> <br></br>
                                                        </div>
                                                        <div className="col-4 text-right p-0  ">
                                                            <Link to="/managebook">

                                                                <label className=" mr-3 mb-0 earningText pointerr poppins_medium">See All</label> <br></br>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="text-center mt-5">


                                                        <PieChart
                                                            data={[
                                                                // { title: 'Sindhi', value: 7, color: '#FAC85B' },
                                                                { title: 'Urdu', value: urduBooks, color: '#B5322A' },
                                                                { title: 'English', value: englishBooks, color: '#517A95' },

                                                            ]}
                                                        />
                                                    </div>
                                                    <div className="text-center mt-5">
                                                        <div className="col-12 ">
                                                            <div className="row">
                                                                <div className="col-1"></div>
                                                                <div className="col-10">
                                                                    <div className="row">



                                                                        <div className="col-4 p-0">

                                                                            <div className="urdusquare"></div>
                                                                            <label className="langText poppins_bold">Urdu</label>
                                                                        </div>
                                                                        <div className="col-4 p-0">

                                                                            <div className="englishsquare"></div>

                                                                            <label className="langText poppins_bold">English</label>
                                                                        </div>
                                                                        <div className="col-4 p-0">

                                                                            {/* <div className="sindisquare"></div>

                                                                            <label className="langText poppins_bold">Sindhi</label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>


                                                </div>

                                                {isLoadingLatest ? <ShimmerThumbnail height={200} className="recentBookCard   mt-3">

                                                </ShimmerThumbnail> :
                                                    <div className="recentBookCard   mt-3">
                                                        <div className="row pl-3 pr-3 ">

                                                            <div className="col-8 p-0 ">

                                                                <label className="ml-2 mb-0 recentBookpublishText poppins_medium">Recent Books Published</label> <br></br>
                                                            </div>
                                                            <div className="col-4 text-right p-0  ">


                                                                <label className=" mr-3 mb-0 recentBookpublishSeeText pointerr poppins_medium" onClick={() => this.viewMore()}>See All</label> <br></br>

                                                            </div>
                                                        </div>

                                                        <div className="row pl-3 pr-3  mt-3">

                                                            <div className="col-3  ">

                                                                <label className="poppins_semibold recentbookpublishTAbs">Book Title</label> <br></br>
                                                            </div>
                                                            <div className="col-5   ">

                                                                <label className="poppins_semibold  recentbookpublishTAbs">Book Name</label> <br></br>
                                                            </div>
                                                            <div className="col-4    ">

                                                                <label className="poppins_semibold  recentbookpublishTAbs">Author Names</label> <br></br>
                                                            </div>
                                                        </div>


                                                        <div className="recentbookHr"></div>

                                                        <div className="RecentBookCardInner">
                                                            <div className="col-12">
                                                                <div className="row  ">

                                                                    <>
                                                                        <div className="col-3 vertical_center ">
                                                                            <img src={this.state.newBookList[0]?.Image} style={{ width: '45px' }}></img>

                                                                        </div>
                                                                        <div className="col-5 vertical_center">
                                                                            <label className="poppins_bold recentBookName">{this.state.newBookList[0]?.Name}</label>
                                                                        </div>
                                                                        <div className="col-4 vertical_center">
                                                                            <label className="poppins_medium recentBookName">{this.state.newBookList[0]?.Author_Name}</label>

                                                                        </div>
                                                                    </>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 ">


                                        {isLoadingTopSelling ? <ShimmerThumbnail height={225} className="topSelling_Card mt-3 ">

                                            <div className="topSelling_Card mt-3 "></div>


                                        </ShimmerThumbnail>
                                            : <div className="topSelling_Card mt-3 ">
                                                <div className="row pl-3 pr-3 mt-2 ">

                                                    <div className="col-8 p-0 ">

                                                        <label className="ml-2 mb-0 topSelling_CardText poppins_medium">Top Selling Book</label> <br></br>
                                                    </div>
                                                    <div className="col-4 text-right p-0  ">
                                                        <Link to="/managebook">

                                                            <label className=" mr-3 mb-0 earningText pointerr poppins_medium">See All</label> <br></br>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 p-0 mt-4">

                                                    <div className="row  ">
                                                        {this.state.bookList.map((item, i) =>
                                                            <div className="col-4  ">
                                                                <img className="topSelling_CardImg" src={item.Image}></img>

                                                            </div>
                                                        )}</div>
                                                </div>
                                            </div>
                                        }
                                        <div className="notificationCard mt-3 ">
                                            <div className="row pl-3 pr-3 mt-2 ">
                                                <div className="col-8 p-0 ">
                                                    <label className="ml-2 mb-0 topSelling_CardText poppins_medium">Notifications</label> <br></br>
                                                </div>
                                                <div className="col-4 text-right p-0  ">
                                                    <Link to="/notification">
                                                        <label className=" mr-3 mb-0 earningText pointerr poppins_medium">See All</label> <br></br>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <img className="NoFileImg" src={NoFile} />
                                                <p className="NoNotyText">No Notification Yet</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

Dashboard.propTypes = {

};


const mapStateToProps = state => ({
    auth: state.auth


});

const mapDispatchToProps = ({
    getConversionBooks,
    getTotalEarning,
    getTotalOrdersAndBook,
    getTopSellingBooks,
    getLatestBook,
    getPieChartValue,
    getAllOrder,
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
