import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './store/actions/authActions'
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { Provider } from 'react-redux';
import store from './store/store';


import Noty from 'noty';

import "../node_modules/noty/lib/noty.css";
import "../node_modules/noty/lib/themes/bootstrap-v4.css";
import Main from './pages/Main';

import Login from './pages/Auth/Login'
import Register from './pages/Auth/Register'
import ForgetPassword from './pages/Auth/ForgetPassword'
import ChangePassword from './pages/Auth/ChangePassword'
import CryptoJS from 'crypto-js';






if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.littlebookcompany.net/v1';
} else {
  axios.defaults.baseURL = 'https://api.littlebookcompany.net/v1';

  //axios.defaults.baseURL = 'http://localhost:4002/v1';
}

axios.defaults.headers.post['Content-Type'] = 'application/json';



// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  console.log(localStorage.jwtToken)
  setAuthToken(localStorage.jwtToken);



  const decoded = jwt_decode(localStorage.jwtToken);
  var user = localStorage.getItem('user');
  console.log(user)
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(user));
  //dispatch(setCurrentUser(user));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    // store.dispatch(logoutUser());

    // Redirect to login
    // window.location.href = '/login';
  }
}

const orderId = "ORD1" + new Date('YmdHis');
const ExpiryTime = new Date().setHours(24);
const TxnDateTime = new Date();
const TxnRefNumber = "T" + " adasdasda";
const AmountTmp = 1 * 100;

const FormattedAmount = AmountTmp;
class App extends Component {

  constructor(props) {
    super(props)
  }
  invokeFunction = (_paPayload) => {
    console.log(_paPayload)
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://sandbox.jazzcash.com.pk/Sandbox/Scripts/hmac-sha256.js";
    script.async = true;
    //script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
    window.addEventListener('message', this.invokeFunction, false);
  }


  CalculateHash = () => {
    var IntegritySalt = '59z921609b'
    var hashString = ''
    var pp_Amount = '10'
    var pp_Version = '1.1'
    var pp_TxnType = ''
    var pp_Language = 'EN'
    var pp_MerchantID = 'MC18784'
    var pp_SubMerchantID = ''
    var pp_Password = '441wvt15x1'
    var pp_BankID = 'TBANK'
    var pp_ProductID = 'RETL'
    var pp_TxnRefNo = 'T20210406202807'
    var pp_TxnDateTime = '20210406202807'
    var pp_TxnCurrency = 'PKR'
    var pp_BillReference = 'billRef'
    var pp_Description = 'Description of transaction'
    var pp_TxnExpiryDateTime = '20210407202807'
    var pp_ReturnURL = 'http://littlebookcompany.net/'
    var pp_SecureHash = ''
    var ppmpf_1 = '1'
    var ppmpf_2 = '2'
    var ppmpf_3 = '3'
    var ppmpf_4 = '4'
    var ppmpf_5 = '5'
    hashString += IntegritySalt + '&';
    hashString += pp_Amount + '&';
    hashString += pp_BankID + '&';
    hashString += pp_BillReference + '&';
    hashString += pp_Description + '&';
    hashString += pp_Language + '&';
    hashString += pp_MerchantID + '&';
    hashString += pp_Password + '&';
    hashString += pp_ProductID + '&';
    hashString += pp_ReturnURL + '&';
    if (pp_SubMerchantID !== '') {
      hashString += pp_SubMerchantID + '&';
    }
    hashString += pp_TxnCurrency + '&';
    hashString += pp_TxnDateTime + '&';
    hashString += pp_TxnExpiryDateTime + '&';
    hashString += pp_TxnRefNo + '&';
    if (pp_TxnType !== '') {
      hashString += pp_TxnType + '&';
    }
    hashString += pp_Version + '&';
    hashString += ppmpf_1 + '&';
    hashString += ppmpf_2 + '&';
    hashString += ppmpf_3 + '&';
    hashString += ppmpf_4 + '&';
    hashString += ppmpf_5 + '&';
    //console.log(hashString)

    hashString = hashString.slice(0, -1);

    var hash = CryptoJS.HmacSHA256(hashString, IntegritySalt);
    pp_SecureHash = hash + ''
    console.log(hashString)
    console.log(pp_SecureHash)
    var bodyFormData = new FormData();
    bodyFormData.append('pp_Version', pp_Version);
    bodyFormData.append('pp_TxnType', pp_TxnType);
    bodyFormData.append('pp_Language', pp_Language);
    bodyFormData.append('pp_MerchantID', pp_MerchantID);
    bodyFormData.append('pp_SubMerchantID', pp_SubMerchantID);
    bodyFormData.append('pp_Password', pp_Password);
    bodyFormData.append('pp_BankID', pp_BankID);
    bodyFormData.append('pp_ProductID', pp_ProductID);
    bodyFormData.append('pp_TxnRefNo', pp_TxnRefNo);
    bodyFormData.append('pp_Amount', pp_Amount);
    bodyFormData.append('pp_TxnDateTime', pp_TxnDateTime);
    bodyFormData.append('pp_BillReference', pp_BillReference);
    bodyFormData.append('pp_Description', pp_Description);
    bodyFormData.append('pp_TxnExpiryDateTime', pp_TxnExpiryDateTime);
    bodyFormData.append('pp_ReturnURL', pp_ReturnURL);
    bodyFormData.append('pp_SecureHash', pp_SecureHash);
    bodyFormData.append('ppmpf_1', ppmpf_1);
    bodyFormData.append('ppmpf_2', ppmpf_2);
    bodyFormData.append('ppmpf_3', ppmpf_3);
    bodyFormData.append('ppmpf_4', ppmpf_4);
    bodyFormData.append('ppmpf_5', ppmpf_5);

    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


    axios({
      method: "post",
      url: "https://sandbox.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform/",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS" },
      //       header := w.Header()
      // header.Add("Access-Control-Allow-Origin", "*")
      // header.Add("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS")
      // header.Add("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With")
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

  }

  render() {

    return (

      // <button onClick={this.CalculateHash}>Click</button>
      <Provider store={store}>
        <Router>

          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgetpassword" component={ForgetPassword} />
            <Route exact path="/changepassword" component={ChangePassword} />






            <Main />

          </Switch>


        </Router>
      </Provider>
    );
  }
}

export default App;
